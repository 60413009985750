<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{
              operation != null ?
                (operation === 'add' ? 'Add Fnb Extras' : (operation === 'detail' ? 'Fnb Extras Detail'
                  : 'Edit Fnb Extras')) : operationTitle
            }}
            <p><small>Master Menu of Food</small></p>
          </template>
          <template v-slot:toolbar v-if="operation === null">
            <b-input-group-append>
              <b-button class="btn btn-primary font-weight-bolder"
                        v-if="globalPropertyId > 0 && $global.hasPermission('fnbmenusstore')"
                        @click="handleChangeOperations('add')"
                        v-b-tooltip.hover :title="`Add New`"
                        variant="success"><i class="fa fa-plus fa-sm"></i> Add New
              </b-button>
              <!--                            <b-button variant="info"-->
              <!--                                      class="ml-2 filter-button"-->
              <!--                                      v-b-tooltip.hover :title="`Filter`"-->
              <!--                                      @click="filters.visible = !filters.visible">-->
              <!--                                <i class="fa fa-filter fa-sm"></i> Filter-->
              <!--                            </b-button>-->
            </b-input-group-append>
          </template>
          <template v-slot:toolbar v-else>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>

          <template v-slot:body>
            <v-card>
              <div class="food-menu-table"
                   v-if="$global.hasPermission('fnbmenusview') && operation === null">

                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select v-model="pagination.totalPerPage"
                                       class="datatable-select"
                                       :options="[10, 50, 100]"
                                       size="sm"></b-form-select>
                        entries</label></div>

                    </div>
                    <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                    <!--                                            <span v-for="(value,key) in filters"-->
                    <!--                                                  :key="key">-->
                    <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                    <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                    <!--                                                        <i class="fa fa-times-circle ml-2"-->
                    <!--                                                           @click="clearFilter(key, [])"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                                <span v-else>-->
                    <!--                                                    <b-tag class="py-2"-->
                    <!--                                                           v-if="value && key !== 'visible'"> <span-->
                    <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                    <!--                                                    <i class="fa fa-times-circle ml-2"-->
                    <!--                                                       @click="clearFilter(key , null)"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                            </span>-->
                    <!--                                        </div>-->
                    <div class="col-sm-12 col-md-6">
                      <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                        <b-form-input type="search"
                                      class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      v-on:keyup.enter="handleSearch"
                                      v-model="search"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                  responsive
                  ref="table"
                  :headers="columns"
                  :items="dataSource"
                  :loading="loading"
                  :items-per-page="parseInt(pagination.totalPerPage)"
                  hide-default-footer
                  class="table table-head-custom table-vertical-center table-responsive"

                >
                  <template v-slot:item.id="record">
                    {{ dataSource.length - record.index }}
                  </template>
                  <template v-slot:item.parent="record">
                    <b-tag variant="primary" v-if="record.item.parent">
                      {{ record.item.parent.category }}
                    </b-tag>
                  </template>
                  <template v-slot:item.action="record" class="action-column">
                    <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                       @click="handleChangeOperations('edit', record.item.id)">
                                                                                       <span
                                                                                         class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/Communication/Write.svg"
                                                class="action-edit-button"
                                              ></inline-svg>
                                                                                         <!--end::Svg Icon-->
                                            </span>

                    </a>
                    <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                       v-show="$global.hasPermission('fnbmenusdestroy')"
                       @click="handleDeleteOperation(record.item.id)">
                                                                                        <span
                                                                                          class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                                                          <!--end::Svg Icon-->
                                            </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                      v-model="pagination.current"
                      :total-rows="pagination.total"
                      :per-page="pagination.totalPerPage"
                      size="lg"
                    ></b-pagination>
                  </div>
                </div><!-- /.pull-right -->
              </div>
              <div v-show="operation !== null && operation !== 'show'">
                <div class="fnbmenus-operation">
                  <b-container fluid>
                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                      <b-row>
                        <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                          <b-row>
                            <b-col sm="6">
                              <b-form-group
                                label="Name Of Fnb Extras *"
                                label-for="title"
                                :invalid-feedback="formErrors.first('title')"
                              >
                                <b-form-input
                                  id="title"
                                  v-model="formFields.title"
                                  type="text"
                                  :state="((formErrors.has('title') ? false : null))"
                                  @focus="$event.target.select()"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="6">
                              <b-form-group
                                label="Rate *"
                                label-for="rate"
                                :invalid-feedback="formErrors.first('rate')"
                              >
                                <b-form-input
                                  id="rate" v-numericOnly.keyup
                                  v-model="formFields.rate"
                                  type="text"
                                  :state="((formErrors.has('rate') ? false : null))"
                                  @focus="$event.target.select()"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row><!--/b-row-->
                          <b-row>
                            <b-col sm="6">
                              <b-form-group
                                label="SGST(%)"
                                label-for="s_gst"
                                :invalid-feedback="formErrors.first('s_gst')"
                              >
                                <b-form-input
                                  id="s_gst" v-numericOnly.keyup
                                  v-model="formFields.s_gst"
                                  type="text"
                                  :state="((formErrors.has('s_gst') ? false : null))"
                                  @focus="$event.target.select()"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="6">
                              <b-form-group
                                label="CGST(%)"
                                label-for="c_gst"
                                :invalid-feedback="formErrors.first('c_gst')"
                              >
                                <b-form-input
                                  id="c_gst" v-numericOnly.keyup
                                  v-model="formFields.c_gst"
                                  type="text"
                                  :state="((formErrors.has('c_gst') ? false : null))"
                                  @focus="$event.target.select()"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <hr/>
                          <b-row class="operation-footer">
                            <b-col sm="12">
                              <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                              >
                                <i v-show="global.pendingRequests > 0"
                                   class="fa fa-spin fa-spinner"></i>
                                <i class="fa fa-save fa-sm"></i>
                                Save
                              </b-button>
                              <b-button variant="danger" class="ml-3"
                                        size="sm"
                                        @click="handleOperationClose()">
                                <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col><!--/b-col-->
                      </b-row><!--/b-row-->
                    </form><!--/form-->
                  </b-container><!--/b-container-->
                </div><!--/.fnbmenus-operation-->
              </div>
            </v-card>
          </template>
        </KTCard>
        <div class="filter-container">
          <div id="filter_list"
               ref="decor_list"
               v-show="!operation &&filters.visible"
               :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
          >
            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
              <b-row>
                <b-col sm="12" class="text-right">
                  <a
                    href="javascript:;"
                    @click="filters.visible = !filters.visible"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary text-right"
                  >
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </a>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Category"
                    label-for="category">
                    <b-form-input id="filter-category" v-model="filters.category"
                                  class="mb-2"></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row>
              <div class="drawer-footer">
                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                  Clear All
                </b-button>
              </div><!-- /.drawer-footer -->
            </form>
          </div>
        </div>
        <!-- /.filter-container -->
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getPropertyId} from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
  title: null,
  rate: null,
  c_gst: 0,
  s_gst: 0,

  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  title: null,
  rate: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'title',
  },
  {
    text: "Rate",
    sortable: false,
    value: 'rate',
  },
  {
    text: "SGST",
    sortable: false,
    value: 's_gst',
  },
  {
    text: "CGST",
    sortable: false,
    value: 'c_gst',
  },
  {
    text: "Action",
    value: "action",
  },

];

export default {
  mixins: [ListingMixin],
  data() {
    return {
      operationTitle: 'Fnb Extras',
      formFields: {...DEFAULT_FORM_STATE},
      filters: {...DEFAULT_FILTER_STATE},
      listUrl: '/fnb/extras',
      columns: COLUMN_DEFINATION(this),

    }
  },
  components: {
    KTCard,
  },
  methods: {
    afterCloseOperation() {
      this.formFields = {...DEFAULT_FORM_STATE}
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
            action: 'fnb'
          },
        ),
      })
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({})
      try {
        const response = await request({
          url: this.formFields.id ? 'fnb/extras/update' : 'fnb/extras/create',
          method: 'post',
          data: this.formFields,
        })
        if (this.formFields.id) {
          this.$global.itemUpdated()
        } else {
          this.$global.itemAdded()
        }
        this.handleOperationClose()
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
          return false
        }
        this.$global.handleServerError(error)
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if (deleteResponse) {
        try {
          const response = await request({
            method: 'post',
            url: '/fnb/extras/delete',
            data: {
              id: id,
            },
          })
          this.loadList(this.listQueryParams)
          this.$global.itemDeleted()
        } catch (errors) {
          this.$global.handleServerError(errors)
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/fnb/extras/detail/${id}`,
        })
        const {data} = response.data
        this.formFields = {
          ...data,
          property_id: data.property ? data.property.id : null
        }
      } catch (e) {
        this.$global.itemEditFails()
        this.formFields = {...DEFAULT_FORM_STATE}
      }
    },
    handleResetFilterClick() {
      this.filters = {...DEFAULT_FILTER_STATE}
      this.isFilterApplied = 'reset'
      this.checkFilterCountStatus()
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
}
</script>


<style scoped>
table {
  width: 100% !important;
}
</style>
