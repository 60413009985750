<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{
              operation != null ?
                (operation === 'add' ? 'Add Food Menu' : (operation === 'detail' ? 'Food Menu Detail'
                  : 'Edit Food Menu')) : operationTitle
            }}
            <p><small>Master Menu of Food</small></p>
          </template>
          <template v-if="operation === null" v-slot:toolbar>
            <b-input-group-append>
              <b-button :title="`Add New`"
                        @click="handleChangeOperations('add')"
                        class="btn btn-primary font-weight-bolder"
                        v-b-tooltip.hover
                        v-if="globalPropertyId > 0 && $global.hasPermission('fnbmenusstore')"
                        variant="success"><i class="fa fa-plus fa-sm"></i> Add New
              </b-button>
            </b-input-group-append>
          </template>
          <template v-else v-slot:toolbar>
            <b-button :disabled="global.pendingRequests > 0" @click="handleSubmitOperation"
                      class="mr-2 mt-3"
                      size="sm"
                      variant="primary"
            >
              <i class="fa fa-spin fa-spinner"
                 v-show="global.pendingRequests > 0"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button @click="handleOperationClose()" class="mt-3" size="sm"
                      variant="danger">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>

          <template v-slot:body>
            <v-card>
              <div class="food-menu-table"
                   v-if="$global.hasPermission('fnbmenusview') && operation === null">

                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select :options="[10, 50, 100]"
                                       class="datatable-select"
                                       size="sm"
                                       v-model="pagination.totalPerPage"></b-form-select>
                        entries</label></div>

                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                        <b-form-input class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      type="search"
                                      v-model="search"
                                      v-on:keyup.enter="handleSearch"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="dataSource"
                  :items-per-page="parseInt(pagination.totalPerPage)"
                  :loading="loading"
                  class="table table-head-custom table-vertical-center table-responsive"
                  hide-default-footer
                  ref="table"
                  responsive
                >
                  <template v-slot:item.id="record">
                    {{ dataSource.length - record.index }}
                  </template>
                  <template v-slot:item.parent="record">
                    <b-tag v-if="record.item.parent" variant="primary">
                      {{ record.item.parent && record.item.parent.parent ? record.item.parent.parent.category : record.item.parent.category }}
                    </b-tag>
                  </template>
                  <template v-slot:item.sub="record">
                    <b-tag v-if="record.item.parent && record.item.parent.parent" variant="primary">
                      {{ record.item.parent.category }}
                    </b-tag>
                  </template>
                  <template class="action-column" v-slot:item.action="record">
                    <a @click="handleChangeOperations('edit', record.item.id)"
                       class="btn btn-icon btn-light btn-hover-primary btn-sm"
                       size="sm">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg class="action-edit-button" src="/media/svg/icons/Communication/Write.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                    <a @click="handleDeleteOperation(record.item.id)"
                       class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                       size="sm"
                       v-show="$global.hasPermission('fnbmenusdestroy')">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg class="action-delete-button" src="/media/svg/icons/General/Trash.svg"/>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                      :per-page="pagination.totalPerPage"
                      :total-rows="pagination.total"
                      size="lg"
                      v-model="pagination.current"
                    ></b-pagination>
                  </div>
                </div><!-- /.pull-right -->
              </div>
              <div v-show="operation !== null && operation !== 'show'">
                <div class="fnbmenus-operation">
                  <b-container fluid>
                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                      <b-row>
                        <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('category')"
                                label="Category *"
                                label-for="category"
                              >
                                <b-form-input
                                  :state="((formErrors.has('category') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="category"
                                  type="text"
                                  v-model="formFields.category"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('parent_category_id')"
                                label="Parent Category"
                                label-for="parent_category_id"
                              >
                                <treeselect
                                  :options="dropdowns.parentCategories"
                                  id="parent_category_id"
                                  @input="handleParentChange"
                                  v-model="formFields.parent_category_id"
                                />
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                :invalid-feedback="formErrors.first('sub_category_id')"
                                label="Sub Category"
                                label-for="sub_category_id"
                              >
                                <treeselect
                                  :options="dropdowns.subCategories"
                                  id="sub_category_id"
                                  v-model="formFields.sub_category_id"
                                />
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row><!--/b-row-->
                          <b-row>
                            <b-col sm="12">
                              <b-form-group
                                :invalid-feedback="formErrors.first('description')"
                                label="Description"
                                label-for="description"
                              >
                                <b-form-textarea
                                  :state="((formErrors.has('description') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="description"
                                  type="text"
                                  v-model="formFields.description"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <hr/>
                          <b-row class="operation-footer">
                            <b-col sm="12">
                              <b-button
                                :disabled="global.pendingRequests > 0"
                                size="sm"
                                type="submit"
                                variant="primary"
                              >
                                <i class="fa fa-spin fa-spinner"
                                   v-show="global.pendingRequests > 0"></i>
                                <i class="fa fa-save fa-sm"></i>
                                Save
                              </b-button>
                              <b-button @click="handleOperationClose()" class="ml-3"
                                        size="sm"
                                        variant="danger">
                                <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col><!--/b-col-->
                      </b-row><!--/b-row-->
                    </form><!--/form-->
                  </b-container><!--/b-container-->
                </div><!--/.fnbmenus-operation-->
              </div>
            </v-card>
          </template>
        </KTCard>
        <div class="filter-container">
          <div
            :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
            id="filter_list"
            ref="decor_list"
            v-show="!operation &&filters.visible"
          >
            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Category"
                    label-for="category">
                    <b-form-input class="mb-2" id="filter-category"
                                  v-model="filters.category"></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row>
              <div class="drawer-footer">
                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                  Clear All
                </b-button>
              </div><!-- /.drawer-footer -->
            </form>
          </div>
        </div>
        <!-- /.filter-container -->
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import Error from "@/core/services/Error";
import {request} from "@/core/services/Request";
import {getPropertyId} from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
  category: null,
  parent_category_id: null,
  sub_category_id: null,
  description: null,

  _method: 'post',
};

const DEFAULT_FILTER_STATE = {

  category: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: true,
    value: 'id',
  },
  {
    text: "Category",
    sortable: true,
    value: 'category',
  },
  {
    text: "Parent Category",
    sortable: true,
    value: 'parent',
  },
  {
    text: "Sub Category",
    sortable: true,
    value: 'sub',
  },
  {
    text: "Description",
    sortable: false,
    value: 'description',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  mixins: [ListingMixin],
  data() {
    return {
      operationTitle: 'Food Menus',
      formFields: {...DEFAULT_FORM_STATE},
      filters: {...DEFAULT_FILTER_STATE},
      listUrl: '/fnb/food/menus',
      columns: COLUMN_DEFINATION(this),

      dropdowns: {
        parentCategories: [],
        subCategories: [],
      }
    }
  },
  components: {
    KTCard,
  },
  mounted() {
    this.getParentCategories()

    if (this.$route.query.id && this.$route.query.operation === 'edit') {
      this.handleEditOperation(this.$route.query.id)
    }
  },
  methods: {
    afterCloseOperation() {
      this.formFields = {...DEFAULT_FORM_STATE}
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
            action: 'menus'
          },
        ),
      })
    },
    async handleSubmitOperation() {

      this.formErrors = new Error({})

      if (this.formFields.sub_category_id) {
        this.formFields.parent_category_id = this.formFields.sub_category_id
      }

      try {
        const response = await request({
          url: this.formFields.id ? 'fnb/food/menus/update' : 'fnb/food/menus/create',
          method: 'post',
          data: this.formFields,
        })
        if (this.formFields.id) {
          this.$global.itemUpdated()
        } else {
          this.$global.itemAdded()
        }
        this.getParentCategories()
        this.handleOperationClose()
      } catch (error) {
        if (error.request && error.request.status && error.request.status === 422) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
          return false
        }
        this.$global.handleServerError(error)
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if (deleteResponse) {
        try {
          const response = await request({
            method: 'post',
            url: '/fnb/food/menus/delete',
            data: {
              id: id,
            },
          })
          this.loadList(this.listQueryParams)
          this.$global.itemDeleted()
        } catch (errors) {
          this.$global.handleServerError(errors)
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/fnb/food/menus/detail/${id}`,
        })
        const {data} = response.data
        let parent_id = null
        let sub_id = null

        if (data.parent) {
          if (data.parent.parent) {
            parent_id = data.parent.parent.id
            sub_id = data.parent.id

            await this.getSubCategories(data.parent.parent.id)
          } else {
            parent_id = data.parent.id
          }
        }

        this.formFields = {
          ...data,
          property_id: data.property ? data.property.id : null,
          parent_category_id: parent_id,
          sub_category_id: sub_id
        }
      } catch (e) {
        this.$global.itemEditFails()
        this.formFields = {...DEFAULT_FORM_STATE}
      }
    },
    handleResetFilterClick() {
      this.filters = {...DEFAULT_FILTER_STATE}
      this.isFilterApplied = 'reset'
      this.loadList(this.listQueryParams)
    },
    async handleParentChange(parent_id) {
      await this.getSubCategories(parent_id)
    },
    async getParentCategories() {
      try {
        const response = await request({
          url: `/dropdowns/parent/categories`,
          method: 'post',
        })

        const {data} = response.data
        this.dropdowns.parentCategories = data.map((item) => {
          return {
            id: item.id,
            label: item.category,
          }
        })
      } catch (e) {

      }
    },
    async getSubCategories(parent_id) {

      if (parent_id) {

        try {
          this.dropdowns.subCategories = [];
          const response = await request({
            url: `/dropdowns/parent/categories/${parent_id}`,
            method: 'post',
          })

          const {data} = response.data
          this.dropdowns.subCategories = data.map((item) => {
            return {
              id: item.id,
              label: item.category,
            }
          })
        } catch (e) {

        }
      }
    },

  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
}
</script>
