<template>
  <div>
    <div>
      <b-tabs lazy>
        <b-tab v-if="this.$route.query.operation !== 'add' || this.$route.query.action === 'menus'" title="Master Food Menu" @click="handleChangeTab('food')"
               :active="tab === 'food'">
          <fnb-food-menu></fnb-food-menu>
        </b-tab>
        <b-tab v-if="this.$route.query.operation !== 'add' || this.$route.query.action === 'package'" title="Packages" @click="handleChangeTab('package')"
               :active="tab === 'package'">
          <fnb-package></fnb-package>
        </b-tab>
        <b-tab v-if="this.$route.query.operation !== 'add' || this.$route.query.action === 'fnb'" v-model="tab" @click="handleChangeTab('fnb')" title="F&B Extra Items"
               :active="tab === 'fnb'">
          <fnb-extras></fnb-extras>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import FnbExtras from "./extras";
import FnbFoodMenu from "./foodMenus";
import FnbPackage from "./packages"

export default {
  components: {
    FnbExtras,
    FnbFoodMenu,
    FnbPackage,
  },
  data() {
    return {
      tab: 'fnb',
    }
  },
  methods: {
    handleChangeTab(type) {
      this.tab = type
    }
  },
  mounted() {

    const action = this.$route.query.action;

    console.log({action});
    if (action === 'fnb') {
      this.tab = 'fnb'
    } else if (action === 'package') {
      this.tab = 'package'
    } else {
      this.tab = 'menus'
    }

    // this.$router.replace('/fnb').catch(() => {
    // })
  },
  watch: {
    tab(val) {
      // this.$router.push({ path: '/fnb', query: { action: val }})
      /*if (val === 'fnb') {
        this.$router.replace('/fnb')
      } else if (val === 'package') {
        this.$router.replace('/fnb/packages')
      } else {
        this.$router.replace('/fnb/food/menus')
      }*/
    }
  }
}
</script>
